<script setup>
import { ref, onMounted, onBeforeUnmount, watch, computed } from 'vue'
import Hls from 'hls.js'

const emit = defineEmits(['timeupdate', 'pause', 'play', 'ended', 'error'])

const props = defineProps({
  src: {
    type: String,
    required: true,
    default: ''
  },
  muted: {
    type: Boolean,
    default: false
  },
  controls: {
    type: Boolean,
    default: true
  },
  autoplay: {
    type: Boolean,
    default: false
  },
  skipDuration: {
    type: Number,
    default: 10
  }
})

const videoRef = ref(null)
const containerRef = ref(null)
const hls = ref(null)
const currentTime = ref(0)
const duration = ref(0)
const progress = ref(0)
const error = ref(null)
const qualities = ref([])
const currentQuality = ref(null)
const showQualityMenu = ref(false)
const isAutoQuality = ref(true)

// Double tap tracking
const lastTapTime = ref(0)
const tapTimeout = ref(null)
const skipAnimationActive = ref(false)
const skipDirection = ref(null)

const qualityLevels = computed(() => {
  return qualities.value.map((level, index) => ({
    index,
    height: level.height,
    bitrate: level.bitrate,
    label: `${level.height}p` + (level.bitrate > 1000000 ? ' HD' : '')
  }))
})

const currentQualityLabel = computed(() => {
  if (isAutoQuality.value) return 'Auto'
  const quality = qualityLevels.value[currentQuality.value]
  return quality ? quality.label : 'Auto'
})

// Double tap handlers
const handleTap = (event) => {
  // Prevent default behavior (including double-click fullscreen)
  event.preventDefault()
  
  // Ignore taps on controls
  if (event.target.closest('.quality-control') || event.target.closest('.quality-menu')) {
    return
  }

  const currentTime = new Date().getTime()
  const tapPosition = event.clientX
  const containerWidth = containerRef.value.offsetWidth
  const isLeftSide = tapPosition < containerWidth / 2

  if (lastTapTime.value > 0 && currentTime - lastTapTime.value < 300) {
    // Double tap detected
    clearTimeout(tapTimeout.value)
    
    if (isLeftSide) {
      skipBackward()
    } else {
      skipForward()
    }
    
    lastTapTime.value = 0
  } else {
    // First tap
    lastTapTime.value = currentTime
    tapTimeout.value = setTimeout(() => {
      lastTapTime.value = 0
    }, 300)
  }
}

const skipForward = () => {
  if (!videoRef.value) return
  skipDirection.value = 'forward'
  skipAnimationActive.value = true
  videoRef.value.currentTime = Math.min(videoRef.value.currentTime + props.skipDuration, videoRef.value.duration)
  
  setTimeout(() => {
    skipAnimationActive.value = false
    skipDirection.value = null
  }, 500)
}

const skipBackward = () => {
  if (!videoRef.value) return
  skipDirection.value = 'backward'
  skipAnimationActive.value = true
  videoRef.value.currentTime = Math.max(videoRef.value.currentTime - props.skipDuration, 0)
  
  setTimeout(() => {
    skipAnimationActive.value = false
    skipDirection.value = null
  }, 500)
}

// Quality selection handlers
const setQuality = (index) => {
  if (!hls.value) return
  
  if (index === -1) {
    isAutoQuality.value = true
    hls.value.currentLevel = -1
  } else {
    isAutoQuality.value = false
    hls.value.currentLevel = index
  }
  
  showQualityMenu.value = false
}

const handleClickOutside = (event) => {
  if (showQualityMenu.value && !event.target.closest('.quality-menu')) {
    showQualityMenu.value = false
  }
}

// HLS initialization and management
const initializePlayer = () => {
  if (!manifestUrl.value) {
    error.value = 'Invalid video source'
    return
  }

  error.value = null

  if (hls.value) {
    destroyPlayer()
  }

  if (Hls.isSupported()) {
    hls.value = new Hls({
      enableWorker: true,
      lowLatencyMode: true,
      autoStartLoad: true,
      startLevel: -1,
      capLevelToPlayerSize: true
    })

    hls.value.attachMedia(videoRef.value)

    hls.value.on(Hls.Events.MEDIA_ATTACHED, () => {
      console.log('HLS media attached')
      hls.value.loadSource(manifestUrl.value)
    })

    hls.value.on(Hls.Events.MANIFEST_PARSED, (event, data) => {
      console.log('HLS manifest parsed, levels:', data.levels)
      qualities.value = data.levels
      
      if (isAutoQuality.value) {
        hls.value.currentLevel = -1
      }
    })

    hls.value.on(Hls.Events.LEVEL_SWITCHED, (event, data) => {
      currentQuality.value = data.level
    })

    hls.value.on(Hls.Events.ERROR, (event, data) => {
      if (data.fatal) {
        switch (data.type) {
          case Hls.ErrorTypes.NETWORK_ERROR:
            console.error('Network error:', data)
            hls.value.startLoad()
            break
          case Hls.ErrorTypes.MEDIA_ERROR:
            console.error('Media error:', data)
            hls.value.recoverMediaError()
            break
          default:
            console.error('Fatal error:', data)
            destroyPlayer()
            break
        }
      }
    })

    videoRef.value.addEventListener('timeupdate', onTimeUpdate)
    videoRef.value.addEventListener('pause', onPause)
    videoRef.value.addEventListener('play', onPlay)
    videoRef.value.addEventListener('ended', onEnded)

  } else if (videoRef.value?.canPlayType('application/vnd.apple.mpegurl')) {
    videoRef.value.src = manifestUrl.value
    videoRef.value.addEventListener('timeupdate', onTimeUpdate)
    videoRef.value.addEventListener('pause', onPause)
    videoRef.value.addEventListener('play', onPlay)
    videoRef.value.addEventListener('ended', onEnded)
  } else {
    error.value = 'HLS playback not supported'
  }
}

const destroyPlayer = () => {
  if (videoRef.value) {
    videoRef.value.removeEventListener('timeupdate', onTimeUpdate)
    videoRef.value.removeEventListener('pause', onPause)
    videoRef.value.removeEventListener('play', onPlay)
    videoRef.value.removeEventListener('ended', onEnded)
  }
  
  if (hls.value) {
    hls.value.destroy()
    hls.value = null
  }
}

const manifestUrl = computed(() => {
  if (!props.src) {
    console.log('No source URL provided')
    return ''
  }
  
  // If it's already an m3u8 URL, use it directly
  if (props.src.endsWith('.m3u8')) {
    return props.src
  }
  
  // Handle Cloudflare Stream URLs
  if (props.src.includes('cloudflarestream.com')) {
    // Extract the video ID from the Cloudflare Stream URL
    const matches = props.src.match(/\/([a-f0-9]{32})\//)
    if (matches && matches[1]) {
      const videoId = matches[1]
      // Construct the proper manifest URL for Cloudflare Stream
      return `https://videodelivery.net/${videoId}/manifest/video.m3u8`
    }
  }
  
  // Default fallback
  return `${props.src}/manifest/video.m3u8`
})

const onTimeUpdate = () => {
  if (!videoRef.value) return
  currentTime.value = videoRef.value.currentTime
  duration.value = videoRef.value.duration
  progress.value = (currentTime.value / duration.value) * 100
  emit('timeupdate', {
    currentTime: currentTime.value,
    duration: duration.value,
    progress: progress.value
  })
}

const onPause = () => {
  emit('pause', {
    currentTime: currentTime.value,
    duration: duration.value,
    progress: progress.value
  })
}

const onPlay = () => {
  emit('play', {
    currentTime: currentTime.value,
    duration: duration.value,
    progress: progress.value
  })
}

const onEnded = () => {
  emit('ended', {
    currentTime: currentTime.value,
    duration: duration.value,
    progress: progress.value
  })
}

onMounted(() => {
  if (props.src) {
    initializePlayer()
  }
  document.addEventListener('click', handleClickOutside)
})

onBeforeUnmount(() => {
  destroyPlayer()
  document.removeEventListener('click', handleClickOutside)
})
</script>

<template>
  <div ref="containerRef" class="video-container" @click="handleTap" @dblclick.prevent>
    <div v-if="error" class="video-overlay error">
      {{ error }}
    </div>
    
    <!-- Skip overlay animations -->
    <div v-if="skipAnimationActive" 
         :class="['skip-overlay', skipDirection]">
      <div class="skip-icon">
        <div class="skip-arrow"></div>
        <div class="skip-time">{{ props.skipDuration }}s</div>
      </div>
    </div>
    
    <video
      ref="videoRef"
      class="video-player"
      :controls="controls"
      :muted="muted"
      :autoplay="autoplay"
      playsinline
    />
    
    <!-- Quality selector -->
    <div class="quality-control" v-if="qualities.length > 0">
      <button 
        class="quality-button"
        @click.stop="showQualityMenu = !showQualityMenu"
      >
        {{ currentQualityLabel }}
      </button>
      
      <div v-if="showQualityMenu" class="quality-menu">
        <div 
          class="quality-option"
          :class="{ active: isAutoQuality }"
          @click="setQuality(-1)"
        >
          Auto
        </div>
        <div
          v-for="quality in qualityLevels"
          :key="quality.index"
          class="quality-option"
          :class="{ active: currentQuality === quality.index && !isAutoQuality }"
          @click="setQuality(quality.index)"
        >
          {{ quality.label }}
        </div>
      </div>
    </div>
    
    <!-- <div v-if="duration > 0" class="progress-bar">
      <div class="progress" :style="{ width: `${progress}%` }" />
    </div> -->
  </div>
</template>

<style scoped>
.video-container {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  overflow: hidden;
  background: #000;
  aspect-ratio: 16 / 9;
}

.video-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* Skip animation styles */
.skip-overlay {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 80px;
  height: 80px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fade-in-out 0.5s ease-in-out;
  z-index: 0;
}

.skip-overlay.forward {
  right: 20%;
}

.skip-overlay.backward {
  left: 20%;
}

.skip-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

.skip-arrow {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}

.skip-overlay.forward .skip-arrow {
  border-left: 15px solid white;
  margin-left: 5px;
}

.skip-overlay.backward .skip-arrow {
  border-right: 15px solid white;
  margin-right: 5px;
}

.skip-time {
  font-size: 14px;
  margin-top: 5px;
}

@keyframes fade-in-out {
  0%, 100% { opacity: 0; }
  20%, 80% { opacity: 1; }
}

/* Quality selector styles */
.quality-control {
  position: absolute;
  bottom: 70px;
  right: 20px;
  z-index: 1;
}

.quality-button {
  background: rgba(28, 28, 28, 0.8);
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  transition: background-color 0.2s;
}

.quality-button:hover {
  background: rgba(28, 28, 28, 0.9);
}

.quality-menu {
  position: absolute;
  bottom: 100%;
  right: 0;
  background: rgba(28, 28, 28, 0.9);
  border-radius: 4px;
  padding: 8px 0;
  margin-bottom: 8px;
  min-width: 100px;
}

.quality-option {
  padding: 8px 16px;
  color: white;
  cursor: pointer;
  font-size: 14px;
  white-space: nowrap;
  transition: background-color 0.2s;
}

.quality-option:hover {
  background: rgba(255, 255, 255, 0.1);
}

.quality-option.active {
  color: #006C3E;
  background: rgba(255, 255, 255, 0.05);
}

.progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 4px;
  background-color: rgba(255, 255, 255, 0.3);
}

.progress {
  height: 100%;
  background-color: #006C3E;
  transition: width 0.1s linear;
}
</style>